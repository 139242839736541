/**
 * Add to this list all the fields that contains references to bloks to
 * have these bloks resolved in the returned story object.
 */
export default [
  'CodeTabs.tabs',
  'GitHubCodeSnippet.language',
  'VonageHomePageLanguagePickerSection.languages',
  'BlogArticle.author',
  'BlogArticle.categories',
  'BlogArticle.tags',
];
